.right-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
}
.right-arrow:hover {
  transform: translate(2, 4);
  color: blue;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 2rem;
  cursor: pointer;
}
.left-arrow:hover {
  transform: translate(2, 4);
  color: blue;
}
@media screen and (max-width: 916px) {
  .slider-container {
    margin: 20px;
  }
}
