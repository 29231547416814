.about-page-container {
  margin: 20px;
}
.about-page-container h3 {
  text-decoration: underline;
}
.about-page-container h4 {
  text-decoration: underline;
  text-align: center;
}
.about-page-container .about-page-tabs {
  margin: 10px;
  font-weight: bold;
}
.about-page-container .information-content {
  margin: 10px;
}
.about-page-container .gallery-single-image {
  padding: 12px;
  margin: 10px;
  height: 150px;
  width: 150px;
  overflow: hidden;
}
.about-page-container img {
  width: 100%;
}
