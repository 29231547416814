.schedule-card-container {
  border: 2px solid #e0dada;
  border-radius: 12px;
  box-shadow: 10px gray;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px;
  padding: 10px;
}
.schedule-card-container .schedule-card-info {
  align-items: center;
  width: 100%;
}
.schedule-card-container h4 {
  margin: 2px;
  text-transform: uppercase;
}
.schedule-card-container h5 {
  margin: 4px;
  text-transform: capitalize;
}
.schedule-card-container img {
  height: 50px;
  width: 100%;
}
@media screen and (max-width: 916px) {
  .schedule-card-container {
    border: 2px solid #e0dada;
    border-radius: 12px;
    box-shadow: 10px gray;
    align-items: center;
    display: block;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin: 10px;
    padding: 10px;
  }

  .schedule-card-container img {
    height: 60px;
    width: auto;
  }
}
.schedule-card-container span {
  margin: 2px;
  font-size: smaller;
  white-space: pre-wrap;
}
