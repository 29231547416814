.forum-page-container {
  width: -webkit-fill-available;
  text-align: center;
}
.forum-page-container .layout-map {
  width: 100%;
}
.forum-page-container .card-display {
  display: table;
  margin: 0 auto;
}
.forum-page-container .page-title {
  text-decoration: underline;
  font: bold;
}
.forum-page-container .speakers-display-wide {
  display: block;
}
@media screen and (max-width: 916px) {
  .forum-page-container .speakers-display-wide {
    display: none;
  }
}
.forum-page-container .speakers-display-small {
  display: none;
}
@media screen and (max-width: 916px) {
  .forum-page-container .speakers-display-small {
    display: block;
  }
}
.forum-page-container .expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}
.forum-page-container .expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}
.forum-page-container .expired-notice > p {
  font-size: 1.5rem;
}
.forum-page-container .show-counter {
  padding: 0.5rem;
}
.forum-page-container .show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}
.forum-page-container .show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.forum-page-container .show-counter .countdown.danger {
  color: #f00;
}
.forum-page-container .show-counter .countdown > p {
  margin: 0;
}
.forum-page-container .show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
.collapsible-trigger {
  color: #242427;
  border-radius: 26px;
  padding: 0px 21px;
  background: #cec9c9;
  margin: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapsible-trigger:hover {
  background: #afa7a7;
  transform: translate(1px, -1px);
}
.collapsible-trigger h3 {
  text-align: left;
}
.Collapsible {
  /* border: 1px solid; */
  border-radius: 26px;
  /* margin: 6px; */
}
.download-document-wide {
  display: block;
}
.download-document-small {
  display: none;
}
.download-document-wide .download-document {
  word-break: break-word;
}
.download-document-wide .download-document img {
  height: 300px;
  width: auto;
  margin: 20px;
}

.forum-register-here {
  background-color: #cdcdde;
  border-radius: 20px;
  color: #000;
  padding: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  &:hover {
    box-shadow: 23px rgb(77, 76, 76);
    transform: translate(1px, -1px);
  }
}
@media screen and (max-width: 916px) {
  .download-document-wide {
    display: none;
  }
  .download-document-small {
    display: block;
  }
  .download-document-small .download-document img {
    height: 220px;
    width: auto;
    margin: 20px;
  }
}
