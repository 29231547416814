.register-page {
  justify-content: space-evenly;
  text-decoration: auto;
  text-align: center;
}
.register-page .register-button {
  background-color: #5f5fe8;
  padding: 10px;
  border: 2px solid;
  border-radius: 12px;
  color: white;
  margin: auto;
  width: fit-content;
}
.register-page .volunteer-button {
  margin: 100px 0;
}
.register-page a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: auto;
}
.register-page p {
  padding: 100px;
  font-size: larger;
}
@media screen and (max-width: 916px) {
  .register-page p {
    padding: 10px;
  }
}
.register-page .register-form {
  margin-top: 16px;
  height: 480px;
}
.register-page .register-form iframe {
  width: 80%;
  height: 100%;
}
@media screen and (max-width: 916px) {
  .register-page .register-form iframe {
    width: 300;
    height: 560;
  }
}

.formfacade-embed-container
{
  display: block;
  color: bisque;
}
