.scholarship-container {
  margin: 10px;
  padding: 20px;

  @media screen and (max-width: 916px) {
    text-align: center;
  }
}

/* .scholarship-container {
  margin: 10px;
  padding: 20px;
}
@media screen and (max-width: 916px) {
  .scholarship-container {
    text-align: center;
 }
} */
