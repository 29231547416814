.footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
  border-top: 1px solid whitesmoke;
  background-color: #f0f5f9;
}
@media screen and (max-width: 916px) {
  .footer-container {
    display: block;
    padding: 15px;
    text-align: center;
  }
}
.footer-container h5 {
  text-decoration: underline;
}
.footer-container p {
  padding: 5px;
  margin: 0;
}
.footer-container img {
  height: 18px;
  cursor: pointer;
}
.footer-container .footer-column-element {
  text-align: center;
}
.footer-container .footer-column-element-right {
  align-self: flex-end;
}
.footer-container .footer-column-element-media {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 916px) {
  .footer-container .footer-column-element-media {
    justify-content: space-evenly;
    margin-bottom: 15px;
  }
}
