.view-modal-container .modal-button {
  width: 250px;
}
.view-modal-container .modal-button .view-modal-image {
  height: 250px;
  overflow: hidden;
  border: 1px solid #000;
  border-radius: 12px;
}
.view-modal-container .modal-button .view-modal-image img {
  width: 100%;
}
