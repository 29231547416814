.home-page-container {
  padding: 20px;
}
.home-page-container h1 {
  text-align: center;
}
.home-page-container .upcoming-event {
  text-align: center;
}
.home-page-container .upcoming-event-moreinfo h2 {
  text-align: center;
}
.home-page-container .upcoming-event-moreinfo br {
  display: none;
}
.home-page-container .upcoming-event-moreinfo {
  border: 2px solid #abbecb;
  border-radius: 6px;
  box-shadow: 10px gray;
  align-items: center;
  display: block;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 10px;
  padding: 10px;
  text-align: center;
}
@media screen and (max-width: 916px) {
  .home-page-container .upcoming-event-moreinfo {
    border: 2px solid #abbecb;
    border-radius: 6px;
    box-shadow: 10px gray;
    align-items: center;
    display: block;
    justify-content: space-between;
    align-items: center;
    width: auto;
    text-align: center;
    margin: 10px 0;
  }
  .home-page-container .upcoming-event-moreinfo br {
    display: block;
  }
}
.home-page-container .upcoming-event img {
  width: 64%;
}
@media screen and (max-width: 916px) {
  .home-page-container .upcoming-event img {
    width: 100%;
  }
}
@media screen and (max-width: 916px) {
  .home-page-container .home-page-anniversary {
    text-align: center;
  }
}
.home-page-container .home-page-anniversary .thirty-anniversary {
  display: flex;
  justify-content: space-between;
}
.home-page-container .home-page-anniversary .thirty-anniversary img {
  height: 300px;
}
.home-page-container .home-page-anniversary .thirty-anniversary p {
  margin: 10px;
}
@media screen and (max-width: 916px) {
  .home-page-container .home-page-anniversary .thirty-anniversary {
    display: block;
  }
}
.home-page-container .pastor-dedication {
  text-align: center;
  color: azure;
  background-color: #550505;
  padding-bottom: 0 200px;
}
.home-page-container .pastor-dedication .pastor-dedication-video {
  height: 480px;
  padding: 40px;
}
.home-page-container .pastor-dedication .pastor-dedication-video iframe {
  width: 80%;
  height: 100%;
}
@media screen and (max-width: 916px) {
  .home-page-container .pastor-dedication .pastor-dedication-video {
    height: auto;
  }
  .home-page-container .pastor-dedication .pastor-dedication-video iframe {
    width: 300;
    height: 220;
  }
}
.home-page-container .scholarship-announce {
  padding: 8px;
  height: 100px;
  background-color: bisque;
}
.home-page-container .scholarship-announce h3 {
  letter-spacing: 1.5px;
  word-spacing: 5px;
}
@media screen and (max-width: 916px) {
  .home-page-container .scholarship-announce {
    height: 180px;
  }
}
.home-page-container .translated-version {
  display: flex;
}
.home-page-container .download-document.download-document {
  word-break: break-word;
}
.download-document.download-document img {
  height: 300px;
  width: auto;
  margin: 20px;
}
@media screen and (max-width: 916px) {
  .home-page-container .translated-version {
    display: block;
  }
}
.home-page-container .collapsible-trigger {
  color: #242427;
  border-radius: 6px;
  padding: 0px 21px;
  background: #f1eeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-page-container .collapsible-trigger:hover {
  transform: translate(1px, -1px);
  background: #e4e1e1;
}

.home-page-container .survey-button {
  border: 0px solid;
  border-radius: 6px;
  padding: 6px;
  margin: 12px 0px;
  color: black;
  background: #dad5d5;
  text-decoration: none;
  font-size: large;
}
.home-page-container .survey-button .survey-button-anchor {
  text-decoration: none;
}

/* CountDown Styles */

.home-page-container .expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}
.home-page-container .expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}
.home-page-container .expired-notice > p {
  font-size: 1.5rem;
}
.home-page-container .show-counter {
  padding: 0.5rem;
}
.home-page-container .show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}
.home-page-container .show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.home-page-container .show-counter .countdown.danger {
  color: #f00;
}
.home-page-container .show-counter .countdown > p {
  margin: 0;
}
.home-page-container .show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
