.nav-bar-container {
  align-items: center;
  border-bottom: 2px solid #c6c6c6;
  display: flex;
  height: 50px;
  justify-content: space-between;
}
@media screen and (max-width: 916px) {
  .nav-bar-container {
    text-align: center;
    border-bottom: 2px solid #c6c6c6;
    height: 300px;
    justify-content: space-between;
    display: block;
  }
}
.nav-bar-container .nav-bar-selector {
  color: black;
  font-size: 18px;
  margin: 0;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}
.nav-bar-container .nav-bar-selector:hover {
  color: #000dff;
}
@media screen and (max-width: 916px) {
  .nav-bar-container .nav-bar-selector {
    padding: 0;
  }
}
@media screen and (max-width: 916px) {
  .nav-bar-container .menu-option {
    display: inline-grid;
    text-align: center;
  }
}
.nav-bar-container .nav-bar-selector-url {
  color: #000dff;
  font-size: 18px;
  margin: 0;
  text-decoration: underline;
  padding: 10px;
  cursor: pointer;
}
.nav-bar-container .nav-bar-title {
  color: black;
  font-size: 14px;
  margin: 0;
  text-decoration: none;
  padding: 10px;
  font-style: italic;
  cursor: pointer;
}
.nav-bar-container .nav-bar-register {
  color: white;
  font-size: 16px;
  margin: 0;
  text-decoration: none;
  padding: 10px;
  background-color: #5f5fe8;
  cursor: pointer;
  border-radius: 11px;
}
.nav-bar-container .nav-bar-donate {
  color: black;
  font-size: 16px;
  margin: 0;
  text-decoration: none;
  padding: 10px;
  background-color: rgba(242, 193, 14, 0.965);
  cursor: pointer;
}
.nav-bar-container .nav-bar-register-url {
  color: white;
  font-size: 16px;
  margin: 0;
  text-decoration: underline;
  padding: 10px;
  background-color: #5f5fe8;
  cursor: pointer;
  border-radius: 11px;
}
.nav-bar-container .nav-bar-donate-url {
  color: black;
  font-size: 16px;
  margin: 0;
  text-decoration: underline;
  padding: 10px;
  background-color: rgba(242, 193, 14, 0.965);
  cursor: pointer;
}
