.page-container {
  margin-top: 10px;
  width: 100%;
}
.page-container .page-header {
  font-size: xx-large;
  text-align: center;
}
.page-container .page-title {
  font-size: x-large;
  text-align: center;
  margin: 10px;
}
