.people-card-container {
  border: 2px solid #f5ecec;
  border-radius: 12px;
  box-shadow: 10px;
  justify-content: space-between;
  margin: 14px;
  text-align: center;
}
.people-card-container .people-card-image {
  height: 150px;
  width: 150px;
  overflow: hidden;
  display: inline-block;
}
.people-card-container .people-card-image img {
  width: 100%;
  border: 1px solid white;
  border-radius: 15px;
  margin: 10px;
  object-fit: fill;
}
.people-card-container:hover {
  box-shadow: 10px #323131;
}
.people-card-container .people-info {
  padding: 12px;
  display: inline-block;
}
