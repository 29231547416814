.prizes-container {
  text-align: center;
}
.prizes-container .prize-item {
}
.prizes-container .prize-item img {
  width: auto;
  border-radius: 10px;
  height: 300px;
}
.prizes-container .prize-item h3 {
  text-align: center;
}
.prizes-container h2 {
  text-align: center;
}
@media screen and (max-width: 916px) {
  .prizes-container .prize-item {
  }
  .prizes-container .prize-item img {
    width: 100%;
    border-radius: 10px;
  }
}
