.sponsors-page-container {
  margin: 20px;
}
.sponsors-page-container h2 {
  text-decoration: underline;
  text-align: center;
}
.sponsors-page-container .sponsor-image-container {
  padding: 12px;
  margin: 10px;
  height: 200px;
  width: 200px;
  overflow: hidden;
}
.sponsors-page-container .sponsor-image-container img {
  width: 100%;
  border-radius: 10px;
}
.sponsors-page-container .sponsors-page-content-wide {
  display: block;
}
@media screen and (max-width: 916px) {
  .sponsors-page-container .sponsors-page-content-wide {
    display: none;
  }
}
.sponsors-page-container .sponsors-page-content-small {
  display: none;
}
@media screen and (max-width: 916px) {
  .sponsors-page-container .sponsors-page-content-small {
    display: block;
  }
}

.sponsors-page-level {
  text-align: center;
  padding: 10px;
  font-size: x-large;
  font-style: italic;
  font-weight: bold;
  margin: 5px 0;
}
